<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->

  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          shallShowUserProfileSidebar =
            false
      "
    />

    <b-modal
      id="modal-loader"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      title=""
      size="sm"
    >
      <b-row align-h="center">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Send Messages</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Select Group"
              invalid-feedback="Group is required."
              ref="group"
            >
              <v-select
                multiple
                :closeOnSelect="true"
                ref="group"
                v-model="groupIDs"
                :disabled="dataLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="onlyGroups"
                :clearable="false"
                label="name"
                :reduce="(opt) => opt.id"
                placeholder="Select group"
              >
                <!-- @input="setNumbers()" -->
                <template slot="option" slot-scope="option">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      v-if="!option.avatar"
                      size="30"
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <b-avatar
                      v-else
                      size="30"
                      :src="option.avatar"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <div class="chat-info flex-grow-1 ml-1">
                      <p class="mb-0 font-weight-bolder">
                        {{ option.name }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Select template"
              invalid-feedback="Template is required."
              ref="temp"
            >
              <v-select
                v-model="msgID"
                :disabled="otherLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="items"
                :reduce="(val) => val.id"
                :clearable="false"
                @input="LoadMedia()"
                @search:blur="CheckTemp()"
                ref="temp"
                label="title"
                placeholder="Select template"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Message" ref="msg">
              <b-form-textarea
                :disabled="!rights.add && !rights.edit"
                ref="msg"
                placeholder="your message."
                v-model="myObj.message"
                rows="12"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- <b-col md="12">
            <b-form-group label="Custom Contacts" ref="custom">
              <b-form-textarea
                ref="custom"
                placeholder="Enter phone number seperated by comma"
                v-model="customContacts"
                min-rows="5"
              ></b-form-textarea>
            </b-form-group>
          </b-col> -->

          <b-col md="12">
            <b-button
              variant="success"
              :disabled="requestWA || otherLoading"
              class="mt-2"
              @click="WhatsApp()"
              block
            >
              <div v-if="requestWA">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Send WhatsApp</span>
            </b-button>
          </b-col>

          <!-- Pending -->
          <!-- <b-col md="12" v-if="pendingCount > 0">
            <b-button
              variant="warning"
              :disabled="requestPending || dataLoading"
              class="mt-2"
              @click="SendWhatsApp()"
              block
            >
              <div v-if="requestPending">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Outbox - {{ this.pendingCount }}</span>
            </b-button>
          </b-col> -->

          <!-- Clear Outbox -->
          <b-col md="12" v-if="pendingCount > 0">
            <b-button
              variant="danger"
              class="mt-2"
              @click="ClearOutBox()"
              block
            >
              <div v-if="requestPending">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Clear Outbox</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility4"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit Member</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility4 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="" align-h="center">
          <b-col md="5">
            <b-form-group
              ref="mem_avatar"
              label=""
              invalid-feedback="Avatar is required."
            >
              <b-media no-body ref="picture">
                <div
                  v-if="avLoading == true"
                  class="d-flex justify-content-center mr-1"
                >
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-2"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      @click="$refs.memberAvatar.click()"
                      v-if="avLoading == false"
                      style="border-radius: 100px"
                      fluid
                      height="135px"
                      width="135px"
                    />
                  </b-link>

                  <b-link v-if="avLoading == 'loaded'">
                    <div class="">
                      <b-button
                        @click="deleteAv()"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon position-absolute"
                        variant="danger"
                        style="margin-top: 50px; margin-left: 50px"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                      <b-img
                        :src="groupMember.avatar"
                        style="border-radius: 100px; border: 1px solid gray"
                        height="135px"
                        width="135px"
                      />
                    </div>

                    <br />
                  </b-link>
                  <input
                    type="file"
                    id="memberAvatar"
                    hidden
                    ref="memberAvatar"
                    @change="selectAv()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
              <small class="mr-1 text-danger" v-if="logoerror"
                >Logo is required.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="mem_name"
            >
              <b-form-input
                placeholder="Enter name"
                ref="mem_name"
                v-model.trim="groupMember.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Contacts (add multiples separated by comma)"
              invalid-feedback="Contact is required."
              ref="memberContact"
            >
              <b-form-textarea
                ref="memberContact"
                placeholder="Enter phone number seperated by comma"
                v-model.trim="groupMember.number"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="primary"
              :disabled="memberSaving"
              class="mt-2"
              @click="saveMember()"
              block
            >
              <div v-if="memberSaving">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Members</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group invalid-feedback="Contact are required.">
              <div
                class="d-flex justify-content-between align-items-center pb-50"
              >
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Contacts</label
                >
                <b-spinner
                  v-if="importing"
                  small
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
                <b-badge
                  v-else
                  variant="primary"
                  class="cursor-pointer"
                  @click="
                    byImport ? clearImport() : $refs.importContact2.click()
                  "
                >
                  {{ byImport ? "Clear" : "Import Excel" }}
                </b-badge>
                <!-- <feather-icon
                  v-else
                  icon="FilePlusIcon"
                  size="30"
                  class="text-primary cursor-pointer pb-50"
                  v-b-tooltip.hover.top
                  title="Import"
                  @click="$refs.importContact2.click()"
                /> -->

                <input
                  type="file"
                  id="importBtn2"
                  hidden
                  ref="importContact2"
                  @change="importData('importBtn2')"
                  accept=".txt,.xls,.xlsx"
                />
              </div>
              <b-form-textarea
                ref="moreContacts"
                placeholder="Enter phone numbers seperated by comma or space"
                v-model.trim="customContacts"
                :disabled="byImport"
                rows="8"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              variant="primary"
              :disabled="moreSaving || importing"
              class="mt-2"
              @click="saveMore()"
              block
            >
              <div v-if="moreSaving">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>Add</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      :no-close-on-backdrop="request"
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Groups</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="!request && (visibility2 = false)"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row align-h="center">
          <b-col md="5">
            <b-form-group
              ref="avatar"
              label=""
              invalid-feedback="Avatar is required."
            >
              <b-media no-body ref="picture">
                <div
                  v-if="logoloading == true"
                  class="d-flex justify-content-center mr-1"
                >
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-2"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      @click="$refs.avatarProfile.click()"
                      v-if="logoloading == false"
                      style="border-radius: 100px"
                      fluid
                      height="135px"
                      width="135px"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div class="">
                      <b-button
                        @click="deleteLogo()"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="btn-icon position-absolute"
                        variant="danger"
                        style="margin-top: 50px; margin-left: 50px"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                      <b-img
                        :src="groupObj.avatar"
                        style="border-radius: 100px; border: 1px solid gray"
                        height="135px"
                        width="135px"
                      />
                    </div>

                    <br />
                  </b-link>
                  <input
                    type="file"
                    id="avatarProfile"
                    hidden
                    ref="avatarProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
              <small class="mr-1 text-danger" v-if="logoerror"
                >Logo is required.</small
              >
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="grpName"
            >
              <b-form-input
                placeholder="Enter group name"
                ref="grpName"
                v-model.trim="groupObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Description"
              ref="desc"
              invalid-feedback="Description is required."
            >
              <b-form-textarea
                ref="desc"
                placeholder="Enter description your group"
                v-model.trim="groupObj.description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12" v-if="groupObj.id == 0">
            <b-form-group invalid-feedback="Contacts are required.">
              <div
                class="d-flex justify-content-between align-items-center pb-50"
              >
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Contacts</label
                >
                <b-spinner
                  v-if="importing"
                  small
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
                <b-badge
                  v-else
                  variant="primary"
                  class="cursor-pointer"
                  @click="
                    byImport ? clearImport() : $refs.importContact.click()
                  "
                >
                  {{ byImport ? "Clear" : "Import Excel" }}
                </b-badge>
                <!-- <feather-icon
                  v-else
                  icon="FilePlusIcon"
                  size="30"
                  class="text-primary cursor-pointer pb-50"
                  v-b-tooltip.hover.top
                  title="Import"
                  @click="$refs.importContact.click()"
                /> -->

                <input
                  type="file"
                  id="importBtn"
                  hidden
                  ref="importContact"
                  @change="importData('importBtn')"
                  accept=".txt,.xls,.xlsx"
                />
              </div>

              <b-form-textarea
                ref="grpContacts"
                placeholder="Enter phone numbers seperated by comma or space"
                v-model.trim="customContacts"
                :disabled="byImport"
                rows="8"
              ></b-form-textarea>
            </b-form-group>

            <!-- <b-form-group
              label="Select Members"
              invalid-feedback="Members are required."
              ref="members"
            >
              <v-select
                ref="members"
                multiple
                :closeOnSelect="false"
                v-model="selectedRec"
                :disabled="otherLoading"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contactList"
                :clearable="false"
                placeholder="Select Members"
                label="name"
                @input="setMembers()"
              >
                <template slot="option" slot-scope="option">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      v-if="option.avatar == null || option.avatar == ''"
                      size="30"
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <b-avatar
                      v-else
                      size="30"
                      :src="option.avatar"
                      class="badge-minimal"
                      variant="transparent"
                    />
                    <div class="chat-info flex-grow-1 ml-1">
                      <p class="mb-0 font-weight-bolder">
                        {{ option.name }}
                      </p>
                      <p class="card-text text-truncate">
                        {{ option.details }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-select>
            </b-form-group> -->
          </b-col>

          <b-col md="12">
            <b-button
              variant="primary"
              :disabled="request || importing"
              class="mt-2"
              @click="createGroup()"
              block
            >
              <div v-if="request">
                <b-spinner
                  small
                  variant="light"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </div>
              <span v-else>{{
                groupObj.id == 0 ? "Create Group" : "Save"
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Templates</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <!-- <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12" class="p-0">
            <div
              v-for="(temp, index) in items"
              :key="temp.id"
              class="d-flex pt-0 pl-1 pr-1 pb-1"
            >
              <div class="mr-1">{{ index + 1 }})</div>
              <div v-if="temp.media" class="mr-1">
                <a
                  :href="temp.media"
                  target="_blank"
                  v-if="isImage(temp.media)"
                >
                  <b-img
                    :src="temp.media"
                    style="border-radius: 10px"
                    height="100px"
                    width="100px"
                  />
                </a>

                <a :href="temp.media" target="_blank" v-else>
                  <div class="file-type">
                    {{ temp.media.split(".").pop().toUpperCase() }}
                  </div>
                </a>
              </div>
              <div>
                <h5>{{ temp.message }}</h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form> -->
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col>
            <b-input-group>
              <b-input-group-prepend is-text style="cursor: pointer">
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>

      <b-table
        class="mt-1"
        :tbody-tr-class="rowClass"
        show-empty
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        responsive
        hover
        small
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
      >
        <template #cell(media)="data">
          <div v-if="data.item.media !== null && data.item.media !== ''">
            <a
              :href="data.item.media"
              target="_blank"
              v-if="isImage(data.item.media)"
            >
              <b-img
                :src="data.item.media"
                style="border-radius: 10px"
                height="100px"
                width="100px"
              />
            </a>
            <video
              v-else-if="isVideo(data.item.media)"
              controls
              controlslist="nodownload"
              style="height: 100px; width: 100px"
              @click.stop
            >
              <source :src="data.item.media" type="video/mp4" />
            </video>

            <a :href="data.item.media" target="_blank" v-else>
              <div class="file-type">
                {{ data.item.media.split(".").pop().toUpperCase() }}
              </div>
            </a>
          </div>
          <div v-else>
            <span>No Media</span>
          </div>
        </template>
      </b-table>
      <sidebar-content />
    </b-sidebar>

    <!-- Main Area -->

    <!-- <b-button
      v-if="openedOnce"
      variant="info"
      style="position: fixed; bottom: 1rem; right: 3rem"
      @click="showSidebar()"
    >
      Start
    </b-button> -->
    <!-- <b-row
      style="display: flex; align-items: center; justify-content: center; height: 100%;"
      v-if="dataLoading"
     
    >
      <b-spinner
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row> -->

    <section class="chat-app-window">
      <!-- // v-if="!dataLoading" -->
      <!-- Start Chat Logo -->
      <div v-if="!opened" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="EditIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="showSidebar()">
          Send New Message
        </h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->

        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="openedContact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
              />
              <!-- badge
                :badge-variant="
                  resolveAvatarBadgeVariant(activeChat.contact.status)
                " -->
              <!-- @click.native="shallShowActiveChatContactSidebar = true" -->
              <div>
                <h6 class="mb-0">
                  {{ openedContact.name ? openedContact.name : "--" }}
                </h6>
                <h6 class="mb-0" style="color: rgb(170 170 176)">
                  {{ openedContact.number }}
                </h6>
              </div>
            </div>

            <!-- Contact Actions -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item> View Contact </b-dropdown-item>
                  <b-dropdown-item> Mute Notifications </b-dropdown-item>
                  <b-dropdown-item> Block Contact </b-dropdown-item>
                  <b-dropdown-item> Clear Chat </b-dropdown-item>
                  <b-dropdown-item> Report </b-dropdown-item>
                </b-dropdown>
              </div>
            </div> -->
          </header>
        </div>

        <!-- User Chat Area -->
        <div
          ref="refChatLogPS"
          id="chatcontent"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area myscroll"
        >
          <div
            v-if="chatloading"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <b-spinner
              style="width: 4rem; height: 4rem"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
          <chat-log
            v-else
            :chat-data="openedContact"
            :user-chat="userChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </div>

        <!-- Message Input -->
        <b-alert
          v-if="
            this.openedContact.number == '' || this.openedContact.number == null
          "
          show
          variant="danger"
          class="chat-app-form justify-content-center"
        >
          User's contact number is not updated
        </b-alert>

        <b-form v-else class="chat-app-form" @submit.prevent="send()">
          <b-input-group
            class="input-group-merge form-send-message mr-1"
            ref="message"
          >
            <b-form-input
              class="p-2"
              :disabled="
                this.openedContact.number == '' ||
                this.openedContact.number == null
              "
              v-model="inputMessage"
              placeholder="Enter your message"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="MailIcon"
                size="18"
                v-b-tooltip.hover.top
                title="Templates"
                class="cursor-pointer mr-50"
                @click="visibility3 = true"
              />
              <input
                type="file"
                id="fileProfile"
                hidden
                ref="fileProfile"
                @change="fileUpload()"
                accept="image/*,.pdf,video/mp4"
              />
              <b-spinner
                v-if="loading"
                variant="primary"
                small
                label="Spinning"
              ></b-spinner>
              <feather-icon
                v-else
                icon="PaperclipIcon"
                v-b-tooltip.hover.top
                title="Upload"
                size="18"
                @click="$refs.fileProfile.click()"
                style="cursor: pointer"
              />
            </b-input-group-append>
          </b-input-group>
          <b-button
            variant="primary"
            :disabled="
              this.openedContact.number == '' ||
              this.openedContact.number == null ||
              sending
            "
            @click="send()"
          >
            Send
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <!-- <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="
        shallShowActiveChatContactSidebar
      "
      :contact="activeChat.contact || {}"
    /> -->

    <!-- Sidebar -->
    <portal
      to="content-renderer-sidebar-left"
      v-if="rights.add || rights.edit || rights.delete"
    >
      <!-- :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.id : null
        " -->
      <chat-left-sidebar
        ref="leftBar"
        :chats-contacts="onlyGroups"
        :all-groups="allGroups"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="getChat"
        @open-new="showSidebar"
        @open-group="openGroup"
        @del-group="deleteGroup"
        @close-chat="opened = false"
        @add-member="addOpen"
        @edit-member="openMember"
        @delete-member="deleteMember"
        :dataLoading="dataLoading"
        :rights="rights"
      />
    </portal>
  </div>
</template>

<script>
import store from "@/store";
import { ref, onUnmounted, nextTick } from "@vue/composition-api";
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BSidebar,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BModal,
  BSpinner,
  BAlert,
  BInputGroupPrepend,
  BInputGroupAppend,
  BImg,
  BTable,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from "@themeConfig";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import chatStoreModule from "./chatStoreModule";
import ChatActiveChatContentDetailsSidedbar from "./ChatActiveChatContentDetailsSidedbar.vue";
import ChatLog from "./ChatLog.vue";
import useChat from "./useChat";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import readXlsxFile from "read-excel-file";

export default {
  components: {
    // BSV
    BTable,
    BSpinner,
    BFormTextarea,
    BFormCheckbox,
    BSidebar,
    BRow,
    BImg,
    BCol,
    BAlert,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    vSelect,
    BModal,
    BBadge,
    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      msgData: null,
      msgID: 0,
      qrCode: "",
      clientID: "",
      requestWA: false,
      customContacts: "",
      pendingCount: 0,
      requestPending: false,
      myObj: {
        media: "",
        message: "",
        clsIDs: [],
        secIDs: [],
        campusID: this.$store.state.userData.cId,
        userID: this.$store.state.userData.userID,
      },
      logoloading: false,
      groupObj: {
        id: 0,
        name: "",
        avatar: "",
        description: "",
        campusID: this.$store.state.userData.cId,
      },
      groupContacts: [],
      avLoading: false,
      groupMember: {
        id: 0,
        groupID: 0,
        name: "",
        number: "",
        campusID: this.$store.state.userData.cId,
      },
      numList: [],
      groupIDs: [],
      visibility4: false,
      memberSaving: false,
      visibility5: false,
      moreSaving: false,
      visibility3: false,
      visibility2: false,
      visibility: false,
      request: false,
      dataLoading: false,
      otherLoading: false,
      // openedOnce: false,
      classID: [0],
      secID: [0],
      classes: [],
      classesOptions: [{ name: "All Classes", id: 0 }],
      admittedSections: [{ section: "All Sections", id: 0 }],
      items: [],
      template: "",
      chatData: {
        chat: [],
        contacts: [],
      },
      userChat: [],
      opened: false,
      openedContact: null,
      inputMessage: "",
      media: "",
      loading: false,
      settings: {},
      chatloading: false,
      hideFields: false,
      fields: [
        { label: "media", key: "media" },
        { label: "message", key: "message" },
      ],
      fields2: [{ label: "details", key: "details" }],
      searchQuery: "",
      customObj: {
        contactIDs: [],
        numbers: [],
      },
      selectedRec: [],
      searchContacts: "",
      contactList: [],
      allGroups: [],
      onlyGroups: [],
      // onlyGroups: [{ name: "All Groups", localID: 0 }],
      importing: false,
      sending: false,
      byImport: false,
      alreadySet: false,
      rights: {},
    };
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.message.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];

      this.LoadData();
      this.LoadOthers();

      //Pending whatsapp
      var obj = {
        url:
          this.$store.state.domain +
          "Chat/WhatsAppPendingCount?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      var data = await this.get(obj);
      this.clientID = data.db;
      this.pendingCount = data.data;

      // this.$bvModal.hide("modal-loader");
    }
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    clearImport() {
      this.groupContacts = [];
      this.customContacts = "";
      this.byImport = false;
      this.alreadySet = false;
      // console.log(this.customContacts, this.groupContacts);
    },

    async importData(id) {
      this.importing = true;
      this.byImport = true;
      this.alreadySet = false;

      const input = document.getElementById(id).files[0];
      // console.log(input);
      if (input.type == "text/plain") {
        const reader = new FileReader();

        reader.onload = () => {
          this.customContacts = reader.result;
          // console.log(this.customContacts);
          this.importing = false;
        };

        reader.onerror = () => {
          this.importing = false;
        };

        reader.readAsText(input);
      } else {
        await readXlsxFile(input)
          .then((rows) => {
            // `rows` is an array of rows
            console.log(rows);
            if (
              rows[0][0] &&
              rows[0][0].toLowerCase() == "name" &&
              rows[0][1].toLowerCase() == "number"
            ) {
              this.alreadySet = true;
              this.groupContacts = rows.reduce((acc, el, ind) => {
                if (ind >= 1) {
                  // let f = el.filter((cell) => cell);
                  // if (f.length <= 2) { }
                  acc.push({
                    id: 0,
                    groupID: this.groupObj.id,
                    name: el[0],
                    number: el[1] ? "" + el[1] : el[1],
                    avatar: "",
                    campusID: this.$store.state.userData.cId,
                  });
                }
                return acc;
              }, []);

              console.log(this.groupContacts);
            }
            // merging all rows & removing nulls
            var re = /^\+?[0-9\s\(\)-]*$/;
            const result = rows.flatMap((el) =>
              el.filter((cell) => cell && re.test(cell))
            );
            this.customContacts = result.join(",");

            // console.log(this.customContacts);
            this.importing = false;
          })
          .catch((err) => {
            console.log(err);
            this.importing = false;
          });
      }
    },
    getQR() {
      return this.qrCode;
    },
    showSidebar() {
      this.visibility = true;
    },
    hideSideBar() {
      this.visibility = false;
    },
    contactRow(row) {
      // console.log(row);
      this.customObj.contactIDs = row.reduce((ids, c) => {
        ids.push(c.localID);
        return ids;
      }, []);
      // console.log(this.customObj);
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    onRowSelected(row) {
      // console.log(row);
      this.inputMessage = row[0].message;
      this.media = row[0].media;
      this.visibility3 = false;
    },
    async getChat(contact) {
      this.inputMessage = "";
      this.media = null;
      this.chatloading = true;
      this.opened = true;
      this.openedContact = contact;
      // console.log(this.openedContact);
      var config = {
        url:
          this.$store.state.domain +
          "Chat/LoadChatAdmin?db=" +
          this.$store.state.userData.db +
          "&number=" +
          contact.number.split(",")[0],
        token: this.$store.state.userData.token,
      };
      this.userChat = await this.get(config);
      //console.log("chat", this.userChat);

      // this.opened = true;
      this.chatloading = false;
      setTimeout(() => {
        this.setChat();
      }, 200);
    },
    setChat() {
      var elem = this.$refs["refChatLogPS"];
      // console.log(elem);
      elem.scrollTop = elem.scrollHeight;
    },
    fileUpload() {
      var axios = require("axios");
      this.fileProfile = this.$refs.fileProfile.files[0];
      //console.log(this.fileProfile);
      if (this.$refs.fileProfile.files[0] !== "") {
        this.loading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.fileProfile.files[0]);
        ////console.log(this.fileProfile);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            //console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.media = fn1;

            this.loading = false;
          });
      }
    },
    async ClearOutBox() {
      //console.log("clearing...");
      try {
        await this.post({
          url:
            this.$store.state.domain +
            "Chat/ClearOutBox?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: "",
          message: "Outbox cleared.",
          context: this,
          token: this.$store.state.userData.token,
        });

        this.pendingCount = 0;
      } catch (ex) {
      } finally {
      }
    },
    selectlogo() {
      var axios = require("axios");
      let pic = this.$refs.avatarProfile.files[0];

      if (pic !== "") {
        this.logoloading = true;

        let formData = new FormData();
        formData.append("file", pic);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.groupObj.avatar = fn1;
            this.logoloading = "loaded";
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.groupObj.avatar = null;
      this.logoloading = false;
      this.$refs.avatarProfile.value = null;
    },
    selectAv() {
      var axios = require("axios");
      let pic = this.$refs.memberAvatar.files[0];

      if (pic !== "") {
        this.avLoading = true;

        let formData = new FormData();
        formData.append("file", pic);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.groupMember.avatar = fn1;
            this.avLoading = "loaded";
          });
      }
    },
    deleteAv() {
      //console.log(0);
      this.groupMember.avatar = null;
      this.avLoading = false;
      this.$refs.memberAvatar.value = null;
    },
    setMembers() {
      // console.log(this.selectedRec);
      this.groupContacts = this.selectedRec.reduce((acc, el) => {
        acc.push({
          id: 0,
          groupID: 0,
          name: el.name,
          number: el.number,
          avatar: "",
          campusID: this.$store.state.userData.cId,
        });
        return acc;
      }, []);
      // console.log(this.groupContacts);
    },
    openGroup(id) {
      if (id == 0) {
        this.groupObj = {
          id: 0,
          name: "",
          avatar: "",
          description: "",
          campusID: this.$store.state.userData.cId,
        };
        this.groupContacts = [];
        this.customContacts = "";
        this.byImport = false;
        this.alreadySet = false;
        this.logoloading = false;
        this.visibility2 = true;
      } else {
        this.groupObj = { ...id };
        if (this.groupObj.avatar) this.logoloading = "loaded";
        else this.logoloading = false;
        this.visibility2 = true;
      }
      var elem = this.$refs["grpName"];
      elem.state = undefined;
      var elem = this.$refs["desc"];
      elem.state = undefined;
      var elem = this.$refs["grpContacts"];
      elem.state = undefined;
    },
    checkGroupName() {
      var elem = this.$refs["grpName"];
      if (this.groupObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkGroupDesc() {
      var elem = this.$refs["desc"];
      if (this.groupObj.description == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setContacts() {
      if (this.groupObj.id == 0) {
        if (!this.alreadySet) {
          // const filtered = this.customContacts
          //   .replace(/[\n\r\t]/g, " ")
          //   .split(",");
          const filtered = this.customContacts.replace(/\s+/g, ",").split(",");
          // console.log(filtered);
          this.groupContacts = filtered.reduce((acc, el) => {
            el.trim() !== "" &&
              acc.push({
                id: 0,
                groupID: 0,
                name: "",
                number: el.trim(),
                avatar: "",
                campusID: this.$store.state.userData.cId,
              });
            return acc;
          }, []);

          // console.log(this.groupContacts);
        }

        var elem = this.$refs["grpContacts"];
        if (this.groupContacts.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else true;
    },
    checkMembers() {
      if (this.groupObj.id == 0) {
        var elem = this.$refs["members"];
        if (this.groupContacts.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
      return true;
    },
    async createGroup() {
      this.checkGroupName();
      // this.checkGroupDesc();
      // this.checkMembers();

      if (
        this.checkGroupName() == false ||
        this.setContacts() == false
        // this.checkGroupDesc() == false
        // this.checkMembers() == false
      ) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        // console.log(this.groupObj, this.groupContacts);
        if (this.groupObj.id == 0) {
          //Add
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Groups/Save?db=" +
              this.$store.state.userData.db,
            body: {
              group: this.groupObj,
              contacts: this.groupContacts,
            },
            message: "Group added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility2 = false;
            this.LoadData();
          }

          // const chunkSize = 10000;
          // let chunkLength = 0;

          // for (let i = 0; i < this.groupContacts.length; i += chunkSize) {
          //   const chunk = this.groupContacts.slice(i, i + chunkSize);
          //   chunkLength += chunk.length;

          //   console.log(chunk);
          //   var status = await this.post({
          //     url:
          //       this.$store.state.domain +
          //       "Groups/Save?db=" +
          //       this.$store.state.userData.db,
          //     body: {
          //       group: this.groupObj,
          //       contacts: chunk,
          //     },
          //     message: "Group added successfully.",
          //     importing: true,
          //     lastChunk: chunkLength == this.groupContacts.length,
          //     context: this,
          //     token: this.$store.state.userData.token,
          //   });
          //   if (!status) this.request = false;
          //   else if (chunkLength == this.groupContacts.length) {
          //     this.request = false;
          //     this.visibility2 = false;
          //     this.LoadData();
          //   }
          // }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Groups/" +
              this.groupObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.groupObj,
            message: "Group updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility2 = false;
            this.LoadData();
          }
        }
      }
    },

    async deleteGroup(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        console.log(id);
        this.dataLoading = true;
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Groups/Remove?id=" +
            id +
            "&db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          message: "Group deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
        else this.dataLoading = false;
      }
    },
    addOpen(obj) {
      // console.log(obj);
      this.groupObj = { ...obj };
      this.groupContacts = [];
      this.customContacts = "";
      this.byImport = false;
      this.alreadySet = false;
      this.visibility5 = true;
      var elem = this.$refs["moreContacts"];
      elem.state = undefined;
    },
    setMore() {
      if (!this.alreadySet) {
        // const filtered = this.customContacts.replace(/[\n\r\t]/g, " ").split(",");
        const filtered = this.customContacts.replace(/\s+/g, ",").split(",");
        // console.log(filtered);
        this.groupContacts = filtered.reduce((acc, el) => {
          el.trim() !== "" &&
            acc.push({
              id: 0,
              groupID: this.groupObj.id,
              name: "",
              number: el.trim(),
              avatar: "",
              campusID: this.$store.state.userData.cId,
            });
          return acc;
        }, []);

        // console.log(this.groupContacts);
      }

      var elem = this.$refs["moreContacts"];
      if (this.groupContacts.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveMore() {
      if (this.setMore() == false) {
        this.$bvToast.toast("Please enter the contacts.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.moreSaving = true;
        // console.log(this.groupObj, this.groupContacts);
        //Add
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Groups/Save?db=" +
            this.$store.state.userData.db,
          body: {
            group: this.groupObj,
            contacts: this.groupContacts,
          },
          message: "Members added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.moreSaving = false;

        if (status) {
          this.LoadData();
          this.visibility5 = false;
        }
      }
    },

    openMember(data) {
      this.groupMember = { ...data };
      if (this.groupMember.avatar) this.avLoading = "loaded";
      else this.avLoading = false;
      this.visibility4 = true;
      var elem = this.$refs["mem_name"];
      elem.state = undefined;
      var elem = this.$refs["memberContact"];
      elem.state = undefined;
    },
    checkMemName() {
      var elem = this.$refs["mem_name"];
      if (this.groupMember.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMemNumber() {
      var elem = this.$refs["memberContact"];
      this.groupMember.number = this.groupMember.number.replace(/\s+/g, " ");
      if (this.groupMember.number == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveMember() {
      this.checkMemName();
      this.checkMemNumber();

      if (this.checkMemName() == false || this.checkMemNumber() == false) {
        this.$bvToast.toast("Please enter the details properly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        //Edit
        this.memberSaving = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "GroupContacts/" +
            this.groupMember.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.groupMember,
          message: "Member updated successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.memberSaving = false;
        this.visibility4 = false;
        if (status) {
          this.LoadData();
        }
      }
    },
    async deleteMember(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        // console.log(id);
        this.dataLoading = true;
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "GroupContacts/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Member removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
    setNumbers() {
      // console.log(this.groupIDs);
    },

    async WhatsApp() {
      this.requestWA = true;
      ////console.log(this.myObj.message);
      try {
        if (this.groupIDs.length == 0) {
          this.$bvToast.toast("Please select the groups", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else if (this.myObj.message.trim() == 0) {
          this.$bvToast.toast("Please enter message", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          let contList = [];
          this.groupIDs.forEach((elem) => {
            const data = this.allGroups.find(
              (el) => el.group.id == elem
            ).contacts;
            contList = [...contList, ...data];

            // console.log(contList);
          });
          const nums = contList.map((el) => el.number).join(",");
          // console.log(nums);

          let cObj = {
            media: this.msgData.media,
            message: this.myObj.message,
            contacts: nums,
            whatsapp: true,
            userID: this.$store.state.userData.userID,
          };
          // console.log(cObj);

          var resp = await this.postObj({
            url:
              this.$store.state.domain +
              "Chat/SendWhatsAppGroup?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: cObj,
            message: "WhatsApp messages are being processed.",
            context: this,
            token: this.$store.state.userData.token,
          });
        }
      } catch (error) {
      } finally {
        this.requestWA = false;
      }
    },
    async send() {
      //console.log(this.inputMessage);
      if (this.inputMessage.trim() !== "") {
        this.sending = true;
        let cObj = {
          media: this.media,
          message: this.inputMessage,
          contacts: this.openedContact.number,
          whatsapp: true,
          userID: this.$store.state.userData.userID,
        };
        // console.log(cObj);

        var axios = require("axios");
        let config = {
          method: "post",
          url:
            this.$store.state.domain +
            "Chat/SendWhatsApp?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          data: cObj,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        axios(config)
          .then((res) => {
            this.getChat(this.openedContact);
            this.inputMessage = "";
            this.sending = false;
          })
          .catch((err) => {
            //console.log(err);
            this.sending = false;
          });
      }
    },
    async LoadOthers() {
      this.otherLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "MessageTemplate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.items = this.items.filter((el) => el.sendOn == "");
      //console.log("temp", this.items);

      // var obj2 = {
      //   url:
      //     this.$store.state.domain +
      //     "Chat/LoadData?db=" +
      //     this.$store.state.userData.db +
      //     "&cID=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.chatData = await this.get(obj2);
      // // console.log("data", this.chatData.data);
      // this.contactList = [...this.chatData.chat, ...this.chatData.contacts];
      // this.contactList = this.contactList.filter(
      //   (el) => el.number != "" && el.number != null
      // );
      // console.log(this.contactList);

      this.otherLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Groups/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allGroups = await this.get(obj);
      this.onlyGroups = [];
      this.allGroups.forEach((el) => this.onlyGroups.push(el.group));

      // this.onlyGroups.unshift({ name: "All Groups", localID: 0 });

      this.dataLoading = false;
    },
    async LoadMedia() {
      this.msgData = this.items.find((el) => el.id == this.msgID);
      // console.log(this.msgID, this.msgData);
      // var mObj = {
      //   url:
      //     this.$store.state.domain +
      //     "MessageTemplate/GetSelected/?id=" +
      //     this.msgID +
      //     "&db=" +
      //     this.$store.state.userData.db +
      //     "&cID=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.msgData = await this.get(mObj);
      this.myObj.message = this.msgData.message;
      this.myObj.media = this.msgData.media;
      // console.log("######### obj", this.myObj);
    },
    FillSections() {
      //return //console.log(this.classes);
      //console.log(this.classID);
      //   if (this.classID.includes(0) && this.classID.length == 2) {
      //     this.classID = this.classID.filter((e) => e !== 0);
      //   }

      // console.log(this.classID);
      if (
        this.classID[this.classID.length - 1] == -2 ||
        this.classID[this.classID.length - 1] == -1
      ) {
        this.classID = [this.classID[this.classID.length - 1]]; // will be -2 or -1

        this.admittedSections = [{ section: "All Sections", id: 0 }];
        if (this.classID[0] == -2) this.hideFields = true;
        else this.hideFields = false;
      } else {
        this.classID = this.classID.filter((e) => e !== -2 && e !== -1);
        this.hideFields = false;

        if (this.classID[0] == 0 && this.classID.length !== 1) {
          this.classID = this.classID.filter((e) => e !== 0);
          //console.log(this.classID);
          this.classes.forEach((elem) => {
            if (this.classID.includes(elem.cls.id)) {
              if (elem.sections.length != 0) {
                elem.sections.forEach((el) => this.admittedSections.push(el));
              } else {
                // console.log("null");
                this.admittedSections = [{ section: "All Sections", id: 0 }];
              }
            }
          });
        } else if (
          this.classID[this.classID.length - 1] !== 0 &&
          this.classID.length == 1
        ) {
          this.classes.forEach((elem) => {
            if (this.classID.includes(elem.cls.id)) {
              if (elem.sections.length != 0) {
                elem.sections.forEach((el) => this.admittedSections.push(el));
              } else {
                // console.log("null");
                this.admittedSections = [{ section: "All Sections", id: 0 }];
              }
            }
          });
        } else if (this.classID[this.classID.length - 1] == 0) {
          this.classID = [0];
          // console.log(this.classID);
          this.admittedSections = [{ section: "All Sections", id: 0 }];
          this.secID = [0];
        } else {
          this.admittedSections = [{ section: "All Sections", id: 0 }];
          this.secID = [0];
        }
      }
      // console.log(this.classID);
      this.CheckClass();
    },
    checkSections() {
      //console.log(this.secID);
      if (this.secID[0] == 0 && this.secID.length !== 1) {
        this.secID = this.secID.filter((e) => e !== 0);
        //console.log(this.secID);
      } else if (this.secID[this.secID.length - 1] == 0) {
        this.secID = [0];
        //console.log(this.secID);
      }
      this.CheckSec();
    },
    CheckClass() {
      // //console.log(this.my);
      var elem = this.$refs["class"];
      if (this.classID.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSec() {
      if (this.classID[0] != 0) {
        var elem = this.$refs["section"];
        if (this.secID.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckTemp() {
      var elem = this.$refs["temp"];
      if (this.msgID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      // this.myObj.media = null;
      // this.myObj.media = this.msgData.media;
      // console.log(this.myObj);
      this.CheckClass();
      this.CheckSec();
      this.CheckTemp();
      if (
        this.CheckClass() == false ||
        this.CheckSec() == false ||
        this.CheckTemp() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else if (this.myObj.message.trim() === "") {
        return this.$bvToast.toast("Please enter message", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.classID[0] !== 0) {
          this.myObj.clsIDs = this.classID;
        } else {
          this.myObj.clsIDs = [];
        }
        if (this.secID[0] !== 0) {
          ////console.log(this.secID);
          this.myObj.secIDs = this.secID;
        } else {
          this.myObj.secIDs = [];
        }
        //Add
        this.request = true;
        //console.log("obj:", this.myObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Chat/SendMessage?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Message sent successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        // this.myObj.media = null;
        if (status) this.visibility = false;
      }
    },
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = "app-chat";

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
      store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    });

    const { resolveAvatarBadgeVariant } = useChat();

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    };

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([]);
    const contacts = ref([]);

    const fetchChatAndContacts = () => {
      store.dispatch("app-chat/fetchChatsAndContacts").then((response) => {
        chatsContacts.value = response.data.chatsContacts;
        contacts.value = response.data.contacts;
        // eslint-disable-next-line no-use-before-define
        profileUserDataMinimal.value = response.data.profileUser;
      });
    };

    fetchChatAndContacts();

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({});
    const chatInputMessage = ref("");
    const userChat = ref([]);

    const openedOnce = ref(false);
    const opened = ref(false);
    const openChatOfContact = (contact) => {
      // Reset send message input value
      // chatInputMessage.value = "";
      // openedOnce.value = true;

      // store.dispatch("app-chat/getChat", { userId }).then((response) => {
      //   activeChat.value = response.data;

      //   // Set unseenMsgs to 0
      //   const contact = chatsContacts.value.find((c) => c.id === userId);
      //   if (contact) contact.chat.unseenMsgs = 0;

      //   // Scroll to bottom
      // });
      // const openedContact = ref({});

      // openedContact.value = contact;
      // var config = {
      //   url:
      //     store.state.domain +
      //     "Chat/LoadChatAdmin?db=" +
      //     store.state.userData.db +
      //     "&number=" +
      //     contact.number,
      //   headers: {
      //     Authorization: "bearer " + this.$store.state.userData.token,
      //   },
      // };
      // axios(config)
      //   .then((res) => {
      //     if (res.data.status == "success") {
      //       userChat.value = res.data.data;
      //       //console.log("chat", userChat);
      //       opened.value = true;
      //       nextTick(() => {
      //         scrollToBottomInChatLog();
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     //console.log(err);
      //   });

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false;
    };
    const sendMessage = () => {
      if (!chatInputMessage.value) return;
      const payload = {
        contactId: activeChat.value.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: profileUserDataMinimal.value.id,
        message: chatInputMessage.value,
      };
      store.dispatch("app-chat/sendMessage", payload).then((response) => {
        const { newMessageData, chat } = response.data;

        // ? If it's not undefined => New chat is created (Contact is not in list of chats)
        if (chat !== undefined) {
          activeChat.value = { chat, contact: activeChat.value.contact };
          chatsContacts.value.push({
            ...activeChat.value.contact,
            chat: {
              id: chat.id,
              lastMessage: newMessageData,
              unseenMsgs: 0,
            },
          });
        } else {
          // Add message to log
          activeChat.value.chat.chat.push(newMessageData);
        }

        // Reset send message input value
        chatInputMessage.value = "";

        // Set Last Message for active contact
        const contact = chatsContacts.value.find(
          (c) => c.id === activeChat.value.contact.id
        );
        contact.chat.lastMessage = newMessageData;

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog();
        });
      });
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({});
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({});

    const shallShowUserProfileSidebar = ref(false);
    const showUserProfileSidebar = () => {
      store.dispatch("app-chat/getProfileUser").then((response) => {
        profileUserData.value = response.data;
        shallShowUserProfileSidebar.value = true;
      });
    };

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false);

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true;
      }
    };

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openedOnce,
      openChatOfContact,
      sendMessage,
      userChat,
      // openedContact,
      // opened,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="css" scoped>
.myscroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.myscroll::-webkit-scrollbar {
  width: 10px;
}
.myscroll::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
  border-radius: 20px;
}
.myscroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: lightgray;
}
.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
<style>
.hidden_header {
  display: none;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
